import dayjs from 'dayjs';

import {
  locationFailure,
  locationRequest,
  locationSuccess,
} from '@/store/reducers/location';
import {
  providerLocationFailure,
  providerLocationRequest,
  providerLocationSuccess,
} from '@/store/reducers/provider';
import {
  providersFailure,
  providersRequest,
  providersSuccess,
} from '@/store/reducers/providers';

import { Dispatch } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { fetchData } from './fetchData';

export const loadProvider =
  (id: any, actions?: any) => (dispatch: Dispatch, getState: () => RootState) =>
    fetchData({
      endpoint: `provider/${id}`,
      actions: actions || [
        providerLocationRequest,
        providerLocationSuccess,
        providerLocationFailure,
      ],
    })?.(dispatch, getState);

export const loadProviderLocation =
  (luid: any, actions?: any, params = { displayErrorToast: true }) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const { user, createPetForm, petAppointments } = getState();

    const { activePet } = petAppointments;
    const defaultPet = user.data?.animals?.[0];
    const formPet = createPetForm.data;

    /*
     * Uses activePet uuid if available,
     * otherwise uses defaultPet uuid
     * otherwise uses formPet breed, size and type
     * otherwise returns empty string
     */
    const animalQuery = activePet?.uuid
      ? `?animal_uuid=${activePet.uuid}`
      : defaultPet?.uuid
        ? `?animal_uuid=${defaultPet.uuid}`
        : formPet
          ? (() => {
              const params = [
                formPet.breed?.id ? `breed_id=${formPet.breed.id}` : '',
                formPet.size?.id ? `size_id=${formPet.size.id}` : '',
                formPet.type?.id ? `animal_type_id=${formPet.type.id}` : '',
              ]
                .filter(Boolean)
                .join('&');
              return params ? `?${params}` : '';
            })()
          : '';

    return fetchData({
      endpoint: `provider/location/${luid}${animalQuery}`,
      actions: actions || [locationRequest, locationSuccess, locationFailure],
      displayErrorToast: params?.displayErrorToast,
    })?.(dispatch, getState);
  };

export const searchGeo =
  () => (dispatch: Dispatch, getState: () => RootState) =>
    fetchData({
      endpoint: `search/geo?lat=41.88453153469004&long=-87.65234883796981&day=${dayjs().format(
        'YYYY-MM-DD',
      )}`,
      actions: [providersRequest, providersSuccess, providersFailure],
    })?.(dispatch, getState);
